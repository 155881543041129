<template>
  <el-button @click="showDateSelect" size="mini" class="zy-title-date" icon="el-icon-date" plain>
    选择期间
    <el-date-picker v-model="value1" type="monthrange"> </el-date-picker>
  </el-button>
</template>

<script>
export default {
  data() {
    return {
      value1: ''
    }
  },
  methods: {
    showDateSelect() {
      this.$emit('date')
    }
  }
}
</script>

<style lang="less" scoped>
.zy-title-date {
  position: relative;
  color: #66bbf9;
  background: transparent;
  border: 1px solid rgba(255,255,255,0.1);
  cursor: pointer;
  height: 28px;
  cursor: pointer;
  .el-date-editor {
    position: absolute; //绝对定位
    top: 0;
    left: 0;
    opacity: 0; //设置完全透明
  }
}
</style>